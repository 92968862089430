<template>
  <div class="productWrap">
    <a-tabs type="card">
      <a-tab-pane key="1" tab="告警列表">
        <ul class="warnList">
          <li>
            <p>未处理告警数</p>
            <p style="color: red">
              {{ statistics.noHandleCount }}
            </p>
          </li>
          <li>
            <p>告警总数</p>
            <p>{{ statistics.alarmCount }}</p>
          </li>
          <li style="margin: 0 60px">
            <p>紧急</p>
            <p style="color: red">
              {{ statistics.urgentCount }}
            </p>
          </li>
          <li>
            <p>重要</p>
            <p style="color: red">
              {{ statistics.importantCount }}
            </p>
          </li>
          <li style="margin-left: 60px">
            <p>普通</p>
            <p>{{ statistics.ordinaryCount }}</p>
          </li>
        </ul>
        <div style="display: flex; margin-bottom: 20px">
          <a-select v-model="selectList.type" style="width: 180px">
            <a-select-option value="all">
              全部
            </a-select-option>
            <a-select-option value="2">
              紧急
            </a-select-option>
            <a-select-option value="1">
              重要
            </a-select-option>
            <a-select-option value="0">
              普通
            </a-select-option>
          </a-select>
          <a-select
            v-model="selectList.style"
            style="width: 180px; margin: 0 30px"
          >
            <a-select-option value="all">
              全部
            </a-select-option>
            <a-select-option value="0">
              未处理
            </a-select-option>
            <a-select-option value="1">
              已处理
            </a-select-option>
            <a-select-option value="2">
              已忽略
            </a-select-option>
          </a-select>
          <a-select v-model="selectList.timeType" style="width: 180px;">
            <a-select-option value="1">
              告警时间
            </a-select-option>
            <a-select-option value="2">
              处理时间
            </a-select-option>
          </a-select>
          <DateRangePicker
            :tip="['请选择开始日期', '请选择结束日期']"
            style=" margin: 0 30px"
            :dates="nowDates"
            @chooseDate="nowDate"
            @dateClear="onlineDateClear"
          />
          <a-button type="primary" @click="onSearchWarning">
            查询
          </a-button>
        </div>
        <a-table
          style="margin-bottom: 20px"
          :locale="{ emptyText: '暂无数据' }"
          table-layout="auto"
          :columns="columns"
          :pagination="false"
          row-key="id"
          :data-source="data"
        >
          <template slot="level" slot-scope="text, record">
            <span>{{ record.level | warningLevelFilter }}</span>
          </template>
          <template slot="status" slot-scope="text, record">
            <span>{{ record.status | warningStatusFilter }}</span>
          </template>
          <template slot="operation" slot-scope="text, record">
            <a href="javascript:void(0);" @click="onLook(record)">查看</a>
          </template>
        </a-table>
        <Pagination
          :current-page="selectList.page"
          :count="warningTotal"
          @handleChange="changePage"
          @handleChangeSize="changeSize"
        />
      </a-tab-pane>
      <a-tab-pane key="2" tab="规则列表">
        <div class="rulesSearch">
          <a-input-search
            v-model="rulesSearch.rulesKeywords"
            style="width: 400px"
            placeholder="请输入告警规则名称"
            enter-button
            @search="searchRules"
          />
          <a-button type="primary" @click="addRules">
            添加规则
          </a-button>
        </div>
        <a-table
          style="margin-bottom: 20px"
          :locale="{ emptyText: '暂无数据' }"
          table-layout="auto"
          :columns="rulesColumns"
          :pagination="false"
          row-key="id"
          :data-source="rulesData"
        >
          <template slot="level" slot-scope="text, record">
            <span>{{ record.level | warningLevelFilter }}</span>
          </template>
          <template slot="operation" slot-scope="text, record">
            <a
              style="margin-right: 20px"
              href="javascript:void(0);"
              @click="onEdite(record)"
              >编辑规则</a
            >
            <a href="javascript:void(0);" @click="onDelete(record.id)">删除</a>
          </template>
        </a-table>
        <Pagination
          :current-page="rulesSearch.page"
          :count="rulesTotal"
          @handleChange="onChangePage"
          @handleChangeSize="onChangeSize"
        />
      </a-tab-pane>
      <a-tab-pane key="3" tab="规则实例">
        <div class="rulesSearch" style="margin-bottom: 10px">
          <a-input-search
            v-model="caseSearch.keyWord"
            style="width: 400px"
            placeholder="请输入规则实例名称"
            enter-button
            @search="searchCase"
          />
          <a-button type="primary" @click="addCase">
            添加实例
          </a-button>
        </div>
        <p style="color: #999">
          实例用来实现告警规则与设备的绑定
        </p>
        <a-table
          style="margin-bottom: 20px"
          :locale="{ emptyText: '暂无数据' }"
          table-layout="auto"
          :columns="caseColumns"
          :pagination="false"
          row-key="id"
          :data-source="caseData"
        >
          <template slot="status" slot-scope="text, record">
            <span>{{ record.status | startStatusFilter }}</span>
          </template>
          <template slot="operation" slot-scope="text, record">
            <a
              style="margin-right: 20px"
              href="javascript:void(0);"
              @click="onDisabled(record)"
              >{{ record.status === 0 ? "启用" : "禁用" }}</a
            >
            <a href="javascript:void(0);">详情</a>
            <a
              style="margin: 0 20px"
              href="javascript:void(0);"
              @click="caseEdite(record)"
              >编辑实例</a
            >
            <a href="javascript:void(0);" @click="caseDelete(record)">删除</a>
          </template>
        </a-table>
        <Pagination
          :count="caseTotal"
          :current-page="caseSearch.page"
          @handleChange="caseChangePage"
          @handleChangeSize="caseChangeSize"
        />
      </a-tab-pane>
    </a-tabs>
    <!-- 查看告警详情弹窗 -->
    <a-modal
      :width="800"
      title="告警通知详情"
      :visible="isVisible"
      :footer="null"
      @cancel="isVisible = false"
    >
      <div class="warnDetails">
        <ul>
          <li>告警ID</li>
          <li>告警规则描述</li>
          <li>告警等级</li>
          <li>告警状态</li>
        </ul>
        <ul>
          <li>{{ warningDetails.id }}</li>
          <li>{{ warningDetails.ruleRemark }}</li>
          <li>{{ warningDetails.level | warningLevelFilter }}</li>
          <li>{{ warningDetails.status | warningStatusFilter }}</li>
        </ul>
        <ul>
          <li>告警规则名称</li>
          <li>告警设备</li>
          <li>触发时间</li>
          <li>处理时间</li>
        </ul>
        <ul>
          <li>{{ warningDetails.ruleName }}</li>
          <li class="warningEquip" :title="warningDetails.alramDevices">
            {{ warningDetails.alramDevices }}
          </li>
          <li>{{ warningDetails.createTime }}</li>
          <li>{{ warningDetails.alarmHandleTime }}</li>
        </ul>
      </div>
      <p style="margin-left: 14px">
        <span style="margin-right: 100px">处理结果</span
        ><span>{{ warningDetails.handleResult }}</span>
      </p>
    </a-modal>
    <!-- 添加规则弹窗 -->
    <a-modal
      :width="800"
      title="添加规则"
      :visible="rulesDialog"
      ok-text="确认"
      cancel-text="取消"
      @ok="onSubmit"
      @cancel="rulesDialog = false"
    >
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item label="规则名称" prop="name">
          <a-input
            v-model="form.name"
            placeholder="1-32位字符，支持中文，英文，数字及特殊字符"
          />
        </a-form-model-item>
        <a-form-model-item label="告警类型" prop="type">
          <a-radio checked>
            设备告警
          </a-radio>
        </a-form-model-item>
        <a-form-model-item label="告警级别" prop="level">
          <a-select v-model="form.level" placeholder="请选择告警级别">
            <a-select-option value="2">
              紧急
            </a-select-option>
            <a-select-option value="1">
              严重
            </a-select-option>
            <a-select-option value="0">
              普通
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="规则描述" prop="desc">
          <a-textarea v-model="form.desc" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 添加实例弹窗 -->
    <a-modal
      :width="800"
      :title="caseTip"
      :visible="caseDialog"
      ok-text="确认"
      cancel-text="取消"
      @ok="caseSubmit"
      @cancel="closeCaseDialog"
    >
      <a-form-model ref="caseForm" :model="addInstanceForm" :rules="caseRules">
        <a-form-model-item label="添加实例名称" prop="ruleInstanceName">
          <a-input
            v-model="addInstanceForm.ruleInstanceName"
            placeholder="1-32位字符，支持中文，英文，数字及特殊字符"
          />
        </a-form-model-item>
        <a-form-model-item label="选择告警规则" prop="ruleId">
          <a-select
            v-model="addInstanceForm.ruleId"
            placeholder="请选择告警规则"
          >
            <a-select-option
              v-for="rule in rulesListData"
              :key="rule.id"
              :value="rule.id"
            >
              {{ rule.alarmRuleName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="绑定设备" prop="chooseFlag">
          <a-radio-group v-model="addInstanceForm.chooseFlag">
            <a-radio :value="1" @click="initDeviceList">
              不通过设备分组
            </a-radio>
            <a-radio :value="2" @click="initGroupList"> 通过设备分组 </a-radio>
          </a-radio-group>
          <div
            v-if="!!addInstanceForm.chooseFlag"
            :class="['equipChoose', isExpand ? '' : 'equipListexpand']"
          >
            <div style="position: relative">
              <div class="equipList" v-if="addInstanceForm.chooseFlag === 1">
                <a-input-search
                  placeholder="输入设备code"
                  style="margin-bottom: 10px;width:200px"
                  @search="SearchDeviceCode"
                />
                <div class="checkboxGroup">
                  <a-checkbox
                    value=""
                    @click="dealAllChoseDevice"
                    :indeterminate="indeterminate"
                    :checked="isAllCheckDevice"
                  >
                    全部未分组设备
                  </a-checkbox>
                  <a-checkbox-group
                    v-model="checkedDeviceIds"
                    name="checkboxgroup"
                    :options="deviceOptions"
                    @change="dealChoseDevice"
                  />
                </div>
              </div>
              <div class="equipList" v-if="addInstanceForm.chooseFlag === 2">
                <div class="checkboxGroup">
                  <a-checkbox
                    value=""
                    @click="dealAllChoseGroup"
                    :indeterminate="indeterminateGroup"
                    :checked="isAllCheckGroup"
                  >
                    全部设备分组
                  </a-checkbox>
                  <a-checkbox-group
                    v-model="checkedGroupIds"
                    name="checkboxgroup"
                    :options="groupOptions"
                    @change="dealChoseGroup"
                  />
                </div>
              </div>
              <a-icon
                type="up"
                :class="['expand', isExpand ? '' : 'change']"
                @click="isExpand = !isExpand"
              ></a-icon>
            </div>
          </div>
          <div>已选择设备列表:</div>
          <div class="chooseList">
            <div
              class="list"
              v-for="item in showList"
              :key="item.deviceCode"
            >
              {{ item.deviceCode }}
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item label="实例描述" prop="remark">
          <a-textarea v-model="addInstanceForm.remark" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import {
  getWarningStatistic,
  getWarningList,
  getWarningRules,
  editRules,
  getWarningCase,
  deleteWarningRules,
  deleteRuleInstanceById,
  setInstanceStatus,
} from "@/api/warningCenter";
import moment from "moment";
const columns = [
  {
    title: "告警ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "告警规则名称",
    dataIndex: "ruleName",
    key: "ruleName",
  },
  {
    title: "告警规则描述",
    dataIndex: "ruleRemark",
    key: "ruleRemark",
  },
  {
    title: "告警等级",
    scopedSlots: { customRender: "level" },
  },
  {
    title: "触发时间",
    dataIndex: "createTime",
    key: "createTime",
  },
  {
    title: "处理时间",
    dataIndex: "alarmHandleTime",
    key: "alarmHandleTime",
  },
  {
    title: "告警状态",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "处理结果",
    dataIndex: "handleResult",
    key: "handleResult",
  },
  {
    title: "操作",
    width: "60px",
    scopedSlots: { customRender: "operation" },
  },
];
const rulesColumns = [
  {
    title: "告警规则名称",
    dataIndex: "alarmRuleName",
    key: "alarmRuleName",
  },
  {
    title: "告警规则描述",
    dataIndex: "remark",
    key: "remark",
  },
  {
    title: "绑定分组",
    dataIndex: "class",
    key: "class",
  },
  {
    title: "告警级别",
    scopedSlots: { customRender: "level" },
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    key: "createTime",
  },
  {
    title: "操作",
    width: "140px",
    scopedSlots: { customRender: "operation" },
  },
];
const caseColumns = [
  {
    title: "规则实例名称",
    dataIndex: "ruleInstanceName",
    key: "ruleInstanceName",
  },
  {
    title: "实例描述",
    dataIndex: "remark",
    key: "remark",
  },
  {
    title: "启用状态",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    key: "createTime",
  },
  {
    title: "操作",
    width: "250px",
    scopedSlots: { customRender: "operation" },
  },
];
import addRulesInstanceMixin from "./methods/addRulesInstance.mixin.js";
export default {
  name: "Index",
  mixins: [addRulesInstanceMixin],
  components: {
    DateRangePicker,
    Pagination,
  },
  data() {
    return {
      // 重置告警列表的查询时间
      nowDates: [],
      // 告警数据统计
      statistics: {},
      // 告警列表下拉选择值集合
      selectList: {
        page: 1,
        pageSize: 10,
        type: "all",
        style: "all",
        timeType: "1",
        startTime: "",
        endTime: "",
      },
      // 告警列表数据总条数
      warningTotal: 0,
      // 告警列表表格数据
      columns,
      data: [],
      // 告警详情
      warningDetails: {},
      // 查看告警详情弹窗是否显示
      isVisible: false,
      // 告警规则表格数据
      rulesColumns,
      rulesData: [],
      // 告警规则数据总条数
      rulesTotal: 0,
      // 添加规则弹窗是否显示
      rulesDialog: false,
      // 告警规则查询条件
      rulesSearch: {
        page: 1,
        pageSize: 10,
        rulesKeywords: "",
      },
      // 规则实例列表数据总条数
      caseTotal: 0,
      // 规则实例查询条件
      caseSearch: {
        page: 1,
        pageSize: 10,
        keyWord: "",
      },
      // 规则实例表格数据
      caseColumns,
      caseData: [],
      // 添加实例弹窗是否显示
      caseDialog: false,
      // 添加规则表单数据
      form: {
        name: "",
        level: undefined,
        desc: "",
      },
      // 添加规则表单验证规则
      rules: {
        name: [{ required: true, message: "请输入规则名称", trigger: "blur" }],
        level: [
          { required: true, message: "请选择告警级别", trigger: "change" },
        ],
      },
      // 添加实例表单数据
      caseForm: {
        name: "",
        rules: undefined,
        equip: null,
        equipList: null,
      },
      // 添加实例表单验证规则
      caseRules: {
        ruleInstanceName: [
          { required: true, message: "请输入实例名称", trigger: "blur" },
        ],
        ruleId: [
          { required: true, message: "请选择告警规则", trigger: "change" },
        ],
        chooseFlag: [
          { required: true, message: "请选择绑定设备", trigger: "change" },
        ],
      },
      caseTip: "",
    };
  },
  created() {
    getWarningStatistic()
      .then((res) => {
        this.statistics = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
    this.onSearchWarning();
    this.rulesList(1);
    this.caseList();
  },
  methods: {
    // 查看告警详情
    onLook(val) {
      this.warningDetails = val;
      this.isVisible = true;
    },
    // 获取告警列表数据
    warningList(num) {
      this.selectList.page = num;
      let info = this.selectList,
        status = info.style === "all" ? "" : info.style,
        type = info.type === "all" ? "" : info.type;
      if (info.timeType === "1") {
        getWarningList(
          info.page,
          info.pageSize,
          info.startTime,
          info.endTime,
          status,
          type
        )
          .then((res) => {
            let info = res.data;
            this.data = info.records;
            this.warningTotal = info.total;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getWarningList(
          info.page,
          info.pageSize,
          "",
          "",
          status,
          type,
          info.startTime,
          info.endTime
        )
          .then((res) => {
            let info = res.data;
            this.data = info.records;
            this.warningTotal = info.total;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    // 选择告警列表中的分页
    changePage(val) {
      this.warningList(val);
    },
    // 选择告警列表每页数据条数
    changeSize(val) {
      this.selectList.pageSize = val;
      this.onSearchWarning();
    },
    // 选择告警列表中的时间段
    nowDate(val) {
      // 格式化日期
      this.selectList.startTime = moment(val[0]).format("YYYY-MM-DD HH:mm:ss");
      this.selectList.endTime = moment(val[1]).format("YYYY-MM-DD HH:mm:ss");
    },
    onlineDateClear(val) {
      if (val === 0) {
        this.selectList.startTime = "";
        this.selectList.endTime = "";
      }
    },
    // 按条件查询告警规则列表数据
    onSearchWarning() {
      this.warningList(1);
    },
    // 获取告警规则列表数据
    rulesList(num) {
      this.rulesSearch.page = num;
      let info = this.rulesSearch;
      getWarningRules(info.page, info.pageSize, info.rulesKeywords)
        .then((res) => {
          let info = res.data;
          this.rulesData = info.records;
          this.rulesTotal = info.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 选择告警规则列表中的分页
    onChangePage(val) {
      this.rulesList(val);
    },
    // 选择告警规则列表每页数据条数
    onChangeSize(val) {
      this.rulesSearch.pageSize = val;
      this.rulesList(1);
    },
    // 获取规则实例列表数据
    caseList() {
      getWarningCase(this.caseSearch)
        .then((res) => {
          console.log(res);
          this.caseData = res.data.records;
          this.caseTotal = res.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 选择规则实例中的分页
    caseChangePage(val) {
      this.caseSearch.page = val;
      this.caseList();
    },
    // 选择规则实例每页数据条数
    caseChangeSize(val) {
      this.caseSearch.pageSize = val;
      this.caseList();
    },
    // 搜索告警规则
    searchRules() {
      this.rulesList(1);
    },
    // 搜索规则实例
    searchCase() {
      this.caseList();
    },
    // 编辑告警规则
    onEdite(val) {
      if (val.ruleId) {
        this.$router.push(
          `/warning-center/edit-rules?id=${val.id}&ruleId=${val.ruleId}`
        );
      } else {
        this.$router.push(`/warning-center/edit-rules?id=${val.id}`);
      }
    },
    // 删除告警规则
    onDelete(val) {
      this.$confirm({
        title: "您确认删除规则吗？",
        okText: "确定",
        centered: true,
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          deleteWarningRules(val)
            .then(() => {
              this.rulesList(1);
              this.$message.success("删除成功！");
            })
            .catch((error) => {
              console.log(error);
            });
        },
      });
    },
    // 添加规则操作
    addRules() {
      this.form = {};
      this.rulesDialog = true;
    },
    // 添加规则提交操作
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let info = this.form;
          editRules(info.name, info.level, info.desc, 1)
            .then(() => {
              this.rulesList(1);
              this.$message.success("添加成功！");
              this.rulesDialog = false;
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 禁用实例操作
    onDisabled(data) {
      console.log(data);
      if (data.status === 1) {
        this.$confirm({
          title: "您确认禁用实例吗？",
          okText: "确定",
          centered: true,
          okType: "danger",
          cancelText: "取消",
          onOk: () => {
            setInstanceStatus(data.id, 0).then((res) => {
              if (res.success) this.$message.success("禁用成功！");
              this.caseList();
            });
          },
        });
      } else {
        setInstanceStatus(data.id, 1).then((res) => {
          if (res.success) this.$message.success("启用成功！");
          this.caseList();
        });
      }
    },
    // // 编辑实例操作
    // caseEdite() {
    //   this.caseTip = "编辑实例";
    //   this.caseForm = {
    //     name: "skdjis",
    //     rules: "1",
    //     equip: [],
    //     equipList: null,
    //   };
    //   this.caseDialog = true;
    // },
    // 删除实例操作
    caseDelete(data) {
      this.$confirm({
        title: "您确认删除实例吗？",
        okText: "确定",
        centered: true,
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          deleteRuleInstanceById(data.id).then((res) => {
            if (res.success) this.caseList();
          });
          this.$message.success("删除成功！");
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.productWrap {
  margin: 20px 40px;
  .productTitle {
    font-size: 20px;
    font-weight: bold;
  }
  .warnList {
    display: flex;
    margin: 30px 0;
    li {
      text-align: center;
    }
    li:first-of-type {
      margin-right: 120px;
      padding-right: 120px;
      border-right: 1px solid #ccc;
    }
  }
  ::v-deep .ant-pagination {
    text-align: right;
    margin-bottom: 20px;
  }
  ::v-deep .ant-pagination-total-text {
    float: left;
  }
  .rulesSearch {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
.warnDetails {
  display: flex;
  justify-content: space-between;
  text-align: center;
  ul {
    margin: 0;
    li {
      height: 22px;
      margin-bottom: 20px;
    }
    .warningEquip {
      max-width: 260px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.equipChoose {
  // position: absolute;
  // top: 30px;
  // left: 0px;
  background: #fff;
  border: 1px solid #ccc;
  z-index: 99;
  width: 300px;
  padding: 20px;
  transition: all 0.5s;
  .equipList {
    // position: relative;
    height: 200px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    flex-wrap: nowrap;
    align-items: flex-start;
    .checkboxGroup {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }
    ::v-deep .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
  .expand {
    position: absolute;
    top: 200px;
    right: -15px;
    transition: all 0.5s;
  }
  .change {
    transform: rotate(180deg);
    top: 5px !important;
  }
}
.equipListexpand {
  height: 30px;
  padding: 0 20px;
  .equipList {
    height: 30px !important;
    overflow: hidden;
  }
}
.chooseList {
  overflow: auto;
  min-width: 300px;
  height: 100px;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  .list{
    margin: 5px;
    line-height: 14px;
    // width: 120px;
  }
}
</style>
