import { getDeviceData, getdeviceGroupList } from "@/api/equipGroup.js";
import { editRuleCase, getWarningRules } from "@/api/warningCenter";

export default {
  data() {
    return {
      isExpand: true, //添加规则实例的设备列表是否展开
      deviceList: [], //添加规则实例的设备列表
      rulesListData: [], //添加实例查询规则列表
      addInstanceForm: { //添加实例表单数据
        devices: [],
        chooseFlag: null,
        id: null,
        ruleId: "",
        ruleInstanceName: "",
        remark: "",
      },
      isAllCheckDevice: false, //是否全选设备
      indeterminate: false, //复选框是否有被选中项
      deviceOptions: [], 
      checkedDeviceIds: [],
      groupList: [], //分组列表
      isAllCheckGroup: false,
      indeterminateGroup: false,
      groupOptions: [],
      checkedGroupIds: [],
      showList: [], //被选中的设备展示
    };
  },
  computed: {
    //处理接口参数所需要的设备列表
    dealParamsDevices() {
      const data = this.addInstanceForm;
      const params = {
        id: data.id,
        remark: data.remark,
        ruleInstanceName: data.ruleInstanceName,
        ruleId: [Number(data.ruleId)],
      };
      if (this.addInstanceForm.chooseFlag === 1) {
        params.devices = this.checkedDeviceIds.map((item) => {
          const device = this.deviceList.find((_item) => _item.id === item);
          return {
            deviceCode: device.code,
            productCode: device.productCode,
          };
        });
      } else if (this.addInstanceForm.chooseFlag === 2) {
        // console.log(this.checkedGroupIds);
        params.devices = [];
        this.checkedGroupIds.forEach(async (groupId) => {
          const res = await getDeviceData({ page: 1, pageSize: 1000, groupId });
          console.log("分组设备", res);
          res.data.records.forEach((device) => {
            params.devices.push({
              deviceCode: device.code,
              productCode: device.productCode,
            });
          });
        });
      }
      return params;
    },
  },
  watch: {
    dealParamsDevices(val) {
      this.$nextTick(() => {
        this.showList = val.devices;
      });
    },
  },
  methods: {
    //获取设备列表的方法
    getDeviceList(params) {
      getDeviceData(params).then((res) => {
        console.log("设备列表", res);
        if (res.success) {
          this.deviceList = res.data.records;
          this.deviceOptions = res.data.records.map((item) => {
            return {
              label: item.code,
              value: item.id,
            };
          });
        }
      });
    },
    //获取分组列表方法
    getGroupList(params) {
      getdeviceGroupList(params).then((res) => {
        console.log("分组列表", res);
        if (res.success) {
          this.groupList = res.data.records;
          this.groupOptions = res.data.records.map((item) => {
            return {
              label: item.groupName,
              value: item.id,
            };
          });
        }
      });
    },
    //初始化设备列表
    initDeviceList() {
      if (this.deviceList.length > 0) return;
      this.getDeviceList({ page: 1, pageSize: 1000 });
    },
    //初始化分组列表
    initGroupList() {
      if (this.groupList.length > 0) return;
      this.getGroupList({ page: 1, pageSize: 1000 });
    },
    //获取告警规则列表方法
    getAlarmList() {
      if (this.rulesListData.length > 0) return;
      getWarningRules(1, 1000).then((res) => {
        // console.log("规则列表", res);
        this.rulesListData = [...res.data.records];
      });
    },
    //通过设备code搜索设备
    SearchDeviceCode(deviceCode) {
      this.getDeviceList({ page: 1, pageSize: 1000, deviceCode });
    },
    //处理选择设备事件
    dealChoseDevice(checkList) {
      //   console.log('选择项', checkList);
      this.indeterminate =
        !!checkList.length && length < this.deviceOptions.length;
      this.isAllCheckDevice = checkList.length === this.deviceOptions.length;
    },
    //处理设备全选
    dealAllChoseDevice(e) {
      //   console.log(e, this.isAllCheckDevice);
      this.indeterminate = false;
      if (e.target.checked) {
        this.checkedDeviceIds = this.deviceOptions.map((item) => item.value);
      } else {
        this.checkedDeviceIds = [];
      }
      this.isAllCheckDevice = e.target.checked;
    },
    //处理选择分组事件
    dealChoseGroup(checkList) {
      //   console.log('选择项', checkList);
      this.indeterminateGroup =
        !!checkList.length && length < this.groupOptions.length;
      this.isAllCheckGroup = checkList.length === this.groupOptions.length;
    },
    //处理分组全选
    dealAllChoseGroup(e) {
      this.indeterminateGroup = false;
      if (e.target.checked) {
        this.checkedGroupIds = this.groupOptions.map((item) => item.value);
      } else {
        this.checkedGroupIds = [];
      }
      this.isAllCheckGroup = e.target.checked;
    },
    // 添加实例操作
    addCase() {
      this.caseTip = "添加实例";
      this.caseDialog = true;
      this.getAlarmList();
    },
    // 编辑实例操作
    caseEdite(data) {
      this.addInstanceForm.id = data.id;
      this.addInstanceForm.ruleInstanceName = data.ruleInstanceName;
      this.addInstanceForm.remark = data.remark;
      this.caseTip = "编辑实例";
      this.caseDialog = true;
      this.getAlarmList();
    },
    // 确认添加实例操作
    caseSubmit() {
      this.$refs.caseForm.validate((valid) => {
        if (valid) {
          const params = this.dealParamsDevices;
          console.log("添加实例表单", params);
          setTimeout(() => {
            editRuleCase(params).then((res) => {
              console.log("添加实例返回", res);
              if (res.success) {
                this.$message.success("添加成功！");
                this.caseDialog = false;
                this.resetData();
                this.caseList();
              }
            }, 0);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //关闭弹窗方法
    closeCaseDialog() {
      this.caseDialog = false;
      this.resetData();
    },
    //重置数据方法
    resetData() {
      Object.assign(this, {
        sExpand: true,
        deviceList: [],
        rulesListData: [],
        addInstanceForm: {
          devices: [],
          chooseFlag: 0,
          id: null,
          ruleId: "",
          ruleInstanceName: "",
          remark: "",
        },
        isAllCheckDevice: false,
        indeterminate: false,
        deviceOptions: [],
        checkedDeviceIds: [],
        groupList: [],
        isAllCheckGroup: false,
        indeterminateGroup: false,
        groupOptions: [],
        checkedGroupIds: [],
      });
    },
  },
};
